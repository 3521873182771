// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/ragflow/web/src/pages/add-knowledge/components/knowledge-chunk/model';
import model_2 from '/ragflow/web/src/pages/add-knowledge/components/knowledge-file/model';
import model_3 from '/ragflow/web/src/pages/chat/model';

export const models = {
model_1: { namespace: 'add-knowledge.components.knowledge-chunk.model', model: model_1 },
model_2: { namespace: 'add-knowledge.components.knowledge-file.model', model: model_2 },
model_3: { namespace: 'chat.model', model: model_3 },
} as const
